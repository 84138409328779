/* blog */
.card-type {
  position: absolute;
  top: 30px;
  left: 30px;
  padding: 3px 10px;
  color: $white;
  background: $primary-color;
  z-index: 1;
}

.card-meta {
  font-size: 14px;
}

.blog-shape-left {
  position: absolute;
  left: 0;
  bottom: 0;
}

.blog-shape-right {
  position: absolute;
  right: 0;
  top: 50%;
}

.clip-half-cycle {
  clip-path: circle(157% at 50% -100%);
}

.title-border-left {
  position: relative;
  padding-left: 30px;

  &::before {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    height: 100%;
    width: 5px;
    border-radius: 30px;
    background: $primary-color;
  }
}

.post-search {
  border: 0;
  background: transparent;
  position: absolute;
  bottom: 15px;
  right: 0;
}

.tag-list {
  a {
    display: inline-block;
    padding: 10px 22px;
    background: $white;
    box-shadow: 0px 5px 20px 0px rgba(51, 77, 128, 0.12);
    border-radius: 35px;
    margin-bottom: 15px;
    color: $text-color;

    &:hover {
      background: $primary-color;
      color: $white;
      box-shadow: 0;
    }
  }
}

blockquote {
  padding: 30px 40px;
  font-style: italic;
  color: $text-color-dark;
  border-left: 2px solid $primary-color;
  box-shadow: 0px 15px 39px 0px rgba(8, 18, 109, 0.1);
  position: relative;
  margin-bottom: 40px;
  
  &::before {
    position: absolute;
    content: "\f10d";
    font-family: $icon-font;
    font-size: 100px;
    color: rgba($color: $primary-color, $alpha: .3);
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-style: normal;
  }

  p {
    font-style: normal;
    margin-top: 10px;
  }
}

/* /blog */