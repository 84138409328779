/*!------------------------------------------------------------------
[MAIN STYLESHEET]

PROJECT:	Bexer | Business HTML5 Template
VERSION:	1.0.0
-------------------------------------------------------------------*/
/*------------------------------------------------------------------
[TABLE OF CONTENTS]

1. BASE

  1.1. Typography
  1.2. Buttons
  1.3. Common Styles

    1.3.1. Preloader
    1.3.2. Section
    1.3.3. Background Image
    1.3.4. Overlay
    1.3.5. Common Class
    1.3.6. Icon Animation
    1.3.7. Breadcrumb
    1.3.8. Google Map
    1.3.9. Form Control
    1.3.10. Social Icon
    1.3.11. Slick Slider

2. NAVBAR

3. BANNER SLIDER

4. HOMEPAGE

  4.1. Banner Feature / Service Item
  4.2. About Section
  4.3. Funfacts Section
  4.4. Feature Section
  4.5. Service Section
  4.6. Team Section
  4.7. Project Section
  4.8. Testimonial Section

5. CLIENTS LOGO

6. CALL TO ACTION

7. PRICING

8. BLOG

9. FOOTER STYLES

-------------------------------------------------------------------*/
/*  typography */
@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:300,400,500,600,700|Open+Sans:300,400,600,700");
body {
  line-height: 1.6;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 15px;
  color: #818385;
}

p, .paragraph {
  font-weight: 400;
  color: #818385;
  font-size: 15px;
  line-height: 1.6;
  font-family: "Open Sans", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  color: #172231;
  font-family: "Barlow Condensed", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1.2;
  transition: .2s ease;
}

h1, .h1 {
  font-size: 70px;
}

h2, .h2 {
  font-size: 48px;
}

h3, .h3 {
  font-size: 44px;
}

h4, .h4, .subtitle {
  font-size: 22px;
}

h5, .h5 {
  font-size: 18px;
}

h6, .h6 {
  font-size: 16px;
}

/* /typography */
/* Button style */
.btn {
  font-size: 15px;
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  padding: 15.5px 28px;
  border-radius: 35px;
  font-weight: 700;
  border: 1px solid;
  position: relative;
  z-index: 1;
  transition: .3s ease-in;
}

.btn:focus {
  outline: 0;
  box-shadow: none !important;
}

.btn:active {
  box-shadow: none;
}

.btn-lg {
  font-size: 20px;
  padding: 19px 35px;
}

.btn-md {
  font-size: 18px;
  padding: 17px 30px;
}

.btn-sm {
  font-size: 12px;
  padding: 5px 20px;
}

.btn-primary {
  background: #86c33a;
  color: #fff;
  border-color: #86c33a;
}

.btn-primary:active {
  background: #6b9c2e !important;
  border-color: #6b9c2e;
}

.btn-primary:hover {
  background: #6b9c2e;
  border-color: #6b9c2e;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #6b9c2e;
  border-color: #6b9c2e;
}

.btn-gray {
  background: #f5f6fa;
  color: #172231;
  border-color: #f5f6fa;
}

.btn-gray:active {
  background: #86c33a !important;
  border-color: #86c33a;
  color: #fff;
}

.btn-gray:hover {
  background: #86c33a;
  border-color: #86c33a;
  color: #fff;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #6b9c2e;
  border-color: #6b9c2e;
}

.btn-secondary {
  background: transparent;
  color: #172231;
  padding-left: 0;
  border: 0;
}

.btn-secondary:active {
  background: transparent !important;
  color: #86c33a;
}

.btn-secondary:hover {
  background: transparent;
  color: #86c33a;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #86c33a;
  background-color: transparent;
  border: 0;
}

.btn-primary-outline {
  background: transparent;
  color: #172231;
  border: 2px solid #172231;
}

.btn-primary-outline:active {
  background: #86c33a !important;
  border-color: #86c33a;
  color: #fff;
}

.btn-primary-outline:hover {
  background: #86c33a;
  border-color: #86c33a;
  color: #fff;
}

.btn-light {
  background: #fff;
  color: #172231;
  border-color: #e7e7e7;
}

.btn-light:active {
  background: transparent !important;
  border-color: #fff;
  color: #fff;
}

.btn-light:hover {
  background: transparent;
  border-color: #fff;
  color: #fff;
}

.btn-light-alt:active {
  background: #86c33a !important;
  border-color: #86c33a;
  color: #fff;
}

.btn-light-alt:hover {
  background: #86c33a;
  border-color: #86c33a;
  color: #fff;
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  background: transparent;
  border-color: #fff;
  color: #fff;
}

.btn-light-outline {
  background: transparent;
  color: #fff;
  border: 2px solid #fff;
}

.btn-light-outline:active {
  background: #6b9c2e !important;
  border-color: #6b9c2e;
  color: #fff;
}

.btn-light-outline:hover {
  background: #6b9c2e;
  border-color: #6b9c2e;
  color: #fff;
}

.btn-arrow {
  transition: .2s ease;
}

.btn-arrow::before {
  position: absolute;
  content: '';
  height: 8px;
  width: 8px;
  background: transparent;
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
  left: calc( 85% + 5px);
  transition: .2s ease;
}

.btn-arrow::after {
  position: absolute;
  content: '';
  height: 2px;
  width: 15px;
  top: 50%;
  transform: translateY(-50%);
  background: #000;
  transition: .2s ease;
  left: 85%;
}

.btn-arrow:hover::before {
  left: calc( 85% + 10px);
  border-color: #86c33a;
}

.btn-arrow:hover::after {
  width: 20px;
  background: #86c33a;
}

/* hover effect */
.hover-ripple {
  transition: .2s ease-in;
}

.hover-ripple:hover {
  transform: scale(0.97);
}

.hover-ripple:hover::after {
  animation: sonarEffect 1s ease-out 50ms;
}

.hover-ripple::after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  content: '';
  top: 0;
  left: 0;
  padding: 0;
  z-index: -1;
  opacity: 0;
  transform: scale(0.9);
  border-radius: inherit;
}

.hover-ripple.ripple-white:hover::after {
  animation: sonarEffectWhite 1s ease-out 50ms;
}

@keyframes sonarEffect {
  0% {
    opacity: 0.1;
  }
  40% {
    opacity: 0.3;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #86c33a, 0 0 0 10px rgba(255, 255, 255, 0.2);
  }
  100% {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #86c33a, 0 0 0 10px rgba(255, 255, 255, 0.2);
    transform: scale(1.2);
    opacity: 0;
  }
}

@keyframes sonarEffectWhite {
  0% {
    opacity: 0.1;
  }
  40% {
    opacity: 0.3;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #fff, 0 0 0 10px rgba(255, 255, 255, 0.2);
  }
  100% {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #fff, 0 0 0 10px rgba(255, 255, 255, 0.2);
    transform: scale(1.2);
    opacity: 0;
  }
}

/* /button style */
body {
  background-color: #fff;
  overflow-x: hidden;
}

::selection {
  background: #9ed060;
  color: #fff;
}

/* preloader */
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spin {
  height: 50px;
  width: 50px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(134, 195, 58, 0.75) rgba(134, 195, 58, 0.75) rgba(134, 195, 58, 0.25) rgba(134, 195, 58, 0.25);
  border-radius: 100%;
  animation: clockwise 1s linear infinite;
}

@keyframes clockwise {
  to {
    transform: rotate(360deg) translatez(0);
  }
}

/* /preloader */
ol,
ul {
  list-style-type: none;
  margin: 0px;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

a,
button,
select {
  cursor: pointer;
  transition: .2s ease;
}

a:focus,
button:focus,
select:focus {
  outline: 0;
}

a:hover {
  color: #86c33a;
}

a.text-white:hover,
a.text-light:hover,
a.text-dark:hover {
  color: #86c33a !important;
}

/* section */
.section {
  padding-top: 150px;
  padding-bottom: 150px;
}

.section-sm {
  padding-top: 70px;
  padding-bottom: 70px;
}

.section-title {
  margin-bottom: 40px;
}

.section-title-border {
  position: relative;
  height: 5px;
  width: 30px;
  background: #86c33a;
  border-radius: 10px;
  margin-bottom: 20px;
  display: block;
}

.section-title-border::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 50%;
  background: #86c33a;
  border-radius: 10px;
  right: -60%;
  top: 0;
}

.section-title-border::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 25%;
  background: #86c33a;
  border-radius: 10px;
  right: -100%;
  top: 0;
}

.section-title-border.border-center {
  margin-left: calc(50% - 27px);
}

.section-title-border.border-white {
  background: #fff;
}

.section-title-border.border-white::before {
  background: #fff;
}

.section-title-border.border-white::after {
  background: #fff;
}

.section-title-border.border-inline {
  display: inline-block;
  margin-bottom: 5px;
}

.subtitle {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  color: #818385;
}

.section-bottom-lg {
  padding-bottom: 240px;
}

/* /section */
/* background image */
.bg-cover {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.bg-contain {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.bg-fixed {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
}

/* /background image */
/* overlay */
.overlay {
  position: relative;
}

.overlay::before {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #000;
  opacity: .6;
}

.overlay-secondary {
  position: relative;
}

.overlay-secondary::before {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #252d39;
  opacity: .85;
}

/* /overlay */
/* common class */
.outline-0 {
  outline: 0 !important;
}

.d-unset {
  display: unset !important;
}

.bg-primary {
  background: #86c33a !important;
}

.bg-secondary {
  background: #252d39 !important;
}

.bg-secondary-darken {
  background: #1f2630 !important;
}

.bg-gray {
  background: #f5f6fa !important;
}

.bg-gray-white {
  background-image: linear-gradient(to right, #f5f6fa 45%, #fff 0%);
}

.bg-white-gray {
  background-image: linear-gradient(to right, #fff 45%, #f5f6fa 0%);
}

.bg-white2-gray {
  background-image: linear-gradient(to right, #fff 70%, #f5f6fa 0%);
}

.text-primary {
  color: #86c33a !important;
}

.text-color {
  color: #818385;
}

.text-light {
  color: #ddd !important;
}

.text-dark {
  color: #172231;
}

.hilighted {
  background: #252d39;
  padding: 0 5px;
  border-radius: 2px;
  color: #fff;
}

.letter-spacing {
  letter-spacing: 2px;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.zindex-1 {
  z-index: 1;
}

.zindex-0 {
  z-index: 0;
}

.overflow-hidden {
  overflow: hidden;
}

.min-height-400 {
  min-height: 400px;
}

.border-color {
  border-color: #e7e7e7 !important;
}

.border-muted {
  border-color: #404751 !important;
}

.rounded-top-0 {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.flex-basis-33 {
  flex-basis: 33.333333%;
}

.icon {
  font-size: 40px;
}

.icon-sm {
  font-size: 34px;
}

.icon-xs {
  font-size: 22px;
}

.icon-lg {
  font-size: 56px;
}

.box-shadow {
  box-shadow: 0px 15px 39px 0px rgba(8, 18, 109, 0.1);
}

.translate-top {
  transform: translateY(-100px);
}

.transition {
  transition: .2s ease;
}

.font-size-30 {
  font-size: 30px;
}

.font-weight-semebold {
  font-weight: 600 !important;
}

.font-secondary {
  font-family: "Barlow Condensed", sans-serif !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-none:hover {
  text-decoration: none !important;
}

.border-md-right {
  border-right: 1px solid;
}

@media (max-width: 767px) {
  .border-md-right {
    border: 0;
  }
}

/* /common class */
/* icon animation */
.icon-bg {
  position: relative;
  background-color: rgba(134, 195, 58, 0.2);
  display: inline-block;
  height: 100px;
  width: 100px;
  border-radius: 120px 135px 110px 90px;
}

.icon-bg::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 5px;
  background: rgba(134, 195, 58, 0.05);
  border: 2px solid #86c33a;
  border-radius: 130px 120px 160px 130px;
}

.icon-bg .icon {
  line-height: 100px;
}

.water-wave {
  position: relative;
  animation-name: water-wave;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.water-wave::before {
  animation-name: water-wave;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes water-wave {
  0% {
    border-radius: 120px 135px 110px 90px;
    ::before {
      border-radius: 130px 120px 160px 130px;
    }
  }
  25% {
    border-radius: 130px 140px 100px 110px;
    ::before {
      border-radius: 100px 147px 140px 120px;
    }
  }
  50% {
    border-radius: 110px 97px 150px 100px;
    ::before {
      border-radius: 102px 147px 140px 120px;
    }
  }
  75% {
    border-radius: 80px 107px 120px 90px;
    ::before {
      border-radius: 102px 147px 140px 120px;
    }
  }
  100% {
    border-radius: 120px 135px 110px 90px;
    ::before {
      border-radius: 130px 120px 160px 130px;
    }
  }
}

/* /icon animation */
/* breadcrumb */
.breadcrumb-item + .breadcrumb-item::before {
  color: #fff;
}

/* google map */
#map_canvas {
  height: 500px;
}

/* form control */
.form-control {
  height: 55px;
  padding: 0 30px;
}

.form-control-sm {
  height: 45px;
}

.form-control:focus {
  box-shadow: 0px 15px 39px 0px rgba(8, 18, 109, 0.1);
  border-color: #86c33a !important;
}

.focus-shadow-none {
  box-shadow: none !important;
}

textarea.form-control {
  height: 150px;
}

textarea.form-control-sm {
  height: 100px;
}

/* /form control */
/* social icon */
.social-icon li a {
  display: inline-block;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  color: #fff;
  border: 1px solid #fff;
  background: transparent;
  line-height: 45px;
  text-align: center;
}

.social-icon li a:hover {
  color: #86c33a;
  background: #fff;
}

.social-icon-alt li a {
  display: inline-block;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  color: #fff;
  border: 1px solid #fff;
  background: transparent;
  line-height: 45px;
  text-align: center;
}

.social-icon-alt li a:hover {
  color: #fff;
  background: #86c33a;
  border-color: #86c33a;
}

/* /social icon */
/* slick style */
.slick-slide {
  outline: 0;
}

/* slick arrows */
.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background: rgba(134, 195, 58, 0.5);
  color: #fff;
  border: 0;
  line-height: 70px;
  font-size: 35px;
}

.slick-arrow::before {
  position: absolute;
  content: '';
  height: 10px;
  width: 10px;
  background: transparent;
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
  transition: .2s ease;
}

.slick-arrow::after {
  position: absolute;
  content: '';
  height: 2px;
  width: 25px;
  top: 50%;
  transform: translateY(-50%);
  background: #fff;
  transition: .2s ease;
}

@media (max-width: 575px) {
  .slick-arrow {
    display: none;
  }
}

.slick-arrow:focus {
  outline: 0;
}

.slick-arrow:hover {
  background: #86c33a;
}

.prevArrow {
  left: 0px;
}

.prevArrow::before {
  border-left: 2px solid #fff;
  border-top: 2px solid #fff;
  right: 35px;
}

.prevArrow::after {
  right: 20px;
}

.nextArrow {
  right: 0px;
}

.nextArrow::before {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  left: 35px;
}

.nextArrow::after {
  left: 20px;
}

/* /slick arrows */
/* slick dots */
.slick-dots {
  padding-left: 0;
  text-align: center;
  position: absolute;
  bottom: -185px;
  left: 0;
  right: 0;
  z-index: 9;
}

.slick-dots li {
  display: inline-block;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 20px;
  transition: .2s ease;
  box-shadow: 0px 15px 39px 0px rgba(8, 18, 109, 0.1);
}

.slick-dots li img {
  height: 70px;
  width: 70px;
  border-radius: 50%;
}

.slick-dots li.slick-active {
  transform: scale(1.2);
}

/* /slick dots */
/* Navbar */
/* top header */
.top-header {
  font-size: 14px;
}

/* /top header */
/* navigation */
.navigation {
  box-shadow: 0px 9px 20px 0px rgba(0, 0, 0, 0.09);
  position: relative;
  z-index: 1;
}

.navbar {
  padding: 0;
}

.navbar-collapse {
  padding-bottom: 10px;
  transition: .2s ease;
}

.navbar .nav-item .nav-link {
  text-transform: uppercase;
  font-weight: 700;
}

.navbar .dropdown:hover .dropdown-menu {
  visibility: visible;
  opacity: 1;
  transform: scaleX(1);
}

.navbar .dropdown-menu {
  box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.12);
  padding: 15px;
  border: 0;
  top: 100px;
  left: -25px;
  border-radius: 0;
  display: block;
  visibility: hidden;
  transition: .3s ease;
  opacity: 0;
  transform: scale(0.8);
  background: #fff;
}

@media (max-width: 991px) {
  .navbar .dropdown-menu {
    display: none;
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    transform-origin: unset;
  }
}

.navbar .dropdown-menu.show {
  visibility: hidden;
}

@media (max-width: 991px) {
  .navbar .dropdown-menu.show {
    visibility: visible;
    display: block;
  }
}

.navbar .dropdown-item {
  position: relative;
  color: #172231;
  transition: .2s ease;
  font-family: "Open Sans", sans-serif;
}

@media (max-width: 991px) {
  .navbar .dropdown-item {
    text-align: center;
  }
}

.navbar .dropdown-item:not(:last-child) {
  margin-bottom: 10px;
}

.navbar .dropdown-item:hover {
  color: #86c33a;
  background: transparent;
}

.navbar-light .navbar-nav .nav-link {
  color: #172231;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #86c33a;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding: 40px 15px;
}

@media (max-width: 1200px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 40px 10px;
  }
}

@media (max-width: 991px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 10px;
  }
}

/* search */
.search-btn {
  border: 0;
  background: transparent;
}

.search-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 2;
  visibility: hidden;
  transition: .2s ease;
  opacity: 0;
}

.search-wrapper.open {
  visibility: visible;
  opacity: 1;
}

.search-box {
  height: 112px;
  width: 100%;
  border: 0;
  background: #fff;
  font-size: 30px;
  padding: 0;
}

.search-box:focus {
  box-shadow: none !important;
}

.search-close {
  position: absolute;
  right: 5px;
  top: 40px;
  border: 0;
  background: transparent;
  page-break-after: 10px;
  font-size: 20px;
}

/* /navigation */
/* hero slider */
.hero-section {
  padding: 160px 0 270px;
}

.hero-slider {
  overflow-x: hidden;
}

.hero-slider .prevArrow {
  left: -100px;
}

.hero-slider .nextArrow {
  right: -100px;
}

.hero-slider:hover .prevArrow {
  left: 35px;
}

.hero-slider:hover .nextArrow {
  right: 35px;
}

/* /hero slider */
/* banner feature */
.banner-feature-wrapper {
  transform: translateY(-100px);
}

.hover-bg-dark:hover, .hover-bg-dark.active {
  background: #252d39 !important;
}

.hover-bg-dark:hover *, .hover-bg-dark.active * {
  color: #fff !important;
}

/* /banner feature */
/* about */
.image-bg {
  position: absolute;
  top: 20%;
  right: 0;
  z-index: -1;
}

.about-bg-shape {
  position: absolute;
  top: 0;
  right: 0;
}

.philosophy-bg-shape {
  position: absolute;
  left: 0;
  bottom: 0;
}

/* /about */
/* funfacts */
.funfacts {
  overflow-x: hidden;
}

@media (max-width: 991px) {
  .funfacts {
    background: #252d39;
  }
}

.funfacts .container {
  transform: translateX(-50px);
  position: relative;
}

.funfacts .container::before {
  position: absolute;
  content: "";
  right: 100%;
  top: 0;
  height: 100%;
  width: 100%;
  background: #252d39;
}

@media (max-width: 991px) {
  .funfacts .container {
    transform: translate(0);
  }
  .funfacts .container::before {
    display: none;
  }
}

@media (max-width: 991px) {
  .funfacts h3 {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .funfacts h3 {
    font-size: 35px;
  }
}

@media (max-width: 991px) {
  .funfacts .icon-lg {
    font-size: 40px;
  }
}

@media (max-width: 767px) {
  .funfacts .icon-lg {
    font-size: 50px;
  }
}

/* /funfacts */
/* feature */
.feature-img-bg {
  position: relative;
}

.feature-img-bg::before {
  position: absolute;
  content: "";
  background: url(../images/backgrounds/feature-bg.png);
  top: -100px;
  right: -120px;
  height: 150%;
  width: 120%;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: contain;
}

@media (max-width: 991px) {
  .feature-img-bg::before {
    display: none;
  }
}

.play-video {
  position: absolute;
  left: 0;
  bottom: 20%;
}

.play-icon {
  display: inline-block;
  min-width: 90px;
  height: 90px;
  text-align: center;
  line-height: 90px;
  border-radius: 50%;
  box-shadow: 0px 15px 39px 0px rgba(8, 18, 109, 0.1);
  font-size: 30px;
}

/* /feature */
/* service */
.service-wrapper {
  transform: translateY(-200px);
}

.card-icon {
  position: absolute;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background: #86c33a;
  text-align: center;
  line-height: 60px;
  color: #fff;
  font-size: 32px;
  left: 15px;
  top: -90px;
}

.service-bg-dots {
  position: relative;
}

.service-bg-dots::before {
  position: absolute;
  content: "";
  background: url(../images/backgrounds/service-page-2.png);
  top: 0px;
  right: 0px;
  height: 100%;
  width: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
}

/* /service */
/* team */
.team-member-img img {
  position: relative;
  z-index: 2;
}

.team-member-img::before {
  position: absolute;
  content: "";
  background: url(../images/backgrounds/team-member-bg.png);
  top: 0px;
  right: 0px;
  height: 60%;
  width: 60%;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1;
  transition: .2s ease;
}

.team-member-img::after {
  position: absolute;
  content: "";
  background: url(../images/backgrounds/team-member-bg.png);
  bottom: -40px;
  left: 0px;
  height: 60%;
  width: 60%;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1;
  transition: .2s ease;
}

.team-member:hover .team-member-img::before {
  top: -20px;
  right: -20px;
}

.team-member:hover .team-member-img::after {
  bottom: -60px;
  left: -20px;
}

.team-member:hover .hover-icon {
  transform: scale(1);
  opacity: 1;
}

.team-member:hover .hover-icon .social-icon a {
  transform: scale(1);
}

.hover-icon {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(134, 195, 58, 0.9);
  transition: .2s ease;
  transform: scale(0.8);
  z-index: 3;
  opacity: 0;
}

.hover-icon .social-icon a {
  transform: scale(0);
}

/* /team */
/* project */
.project-item img {
  transition: .4s ease-in;
}

.project-item:hover img {
  transform: scale(1.05);
}

.project-item:hover .hover-overlay {
  opacity: 1;
}

.project-item:hover .hover-overlay a {
  transform: translateY(-50%) scale(1);
}

.hover-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  text-align: center;
  background: rgba(134, 195, 58, 0.9);
  transition: .4s ease;
  opacity: 0;
}

.hover-overlay a {
  position: relative;
  font-size: 46px;
  color: #fff;
  top: 50%;
  transform: translateY(-50%) scale(0);
  transition: .4s ease;
  display: inline-block;
}

.uni-plus {
  position: relative;
  padding: 0 20px;
}

.uni-plus::before {
  position: absolute;
  content: '';
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 40px;
  width: 2px;
  background: #fff;
}

.uni-plus::after {
  position: absolute;
  content: '';
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 2px;
  width: 40px;
  background: #fff;
}

.filter-controls li {
  cursor: pointer;
}

.filter-controls li.active {
  color: #86c33a !important;
}

/* /project */
/* testimonial */
.bg-dots {
  position: relative;
}

.bg-dots::before {
  position: absolute;
  content: "";
  background: url(../images/backgrounds/testimonial-bg-1.png);
  top: 0px;
  right: 0px;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
}

.bg-dots .container {
  position: relative;
  z-index: 2;
}

.testimonial-slider .nextArrow,
.testimonial-slider .prevArrow {
  opacity: 0;
  transition-delay: 1s;
}

.testimonial-slider:hover .nextArrow {
  opacity: 1;
  right: -100px;
  transition-delay: 0s;
}

.testimonial-slider:hover .prevArrow {
  opacity: 1;
  left: -100px;
  transition-delay: 0s;
}

.bg-quote {
  position: relative;
}

.bg-quote::before {
  position: absolute;
  content: "\f10d";
  font-family: "FontAwesome";
  font-size: 150px;
  color: rgba(134, 195, 58, 0.3);
  top: -10px;
  line-height: 1;
  left: 50%;
  transform: translateX(-50%);
}

/* /testimonial */
/* clients logo */
.clients-logo {
  text-align: center;
  position: relative;
  height: 120px;
  width: 180px;
}

.clients-logo:hover .white {
  opacity: 0;
}

.clients-logo:hover .primary {
  opacity: 1;
}

.clients-logo .white {
  opacity: 1;
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  text-align: center;
  padding: 0 10px;
}

.clients-logo .primary {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  text-align: center;
  padding: 0 10px;
}

.customer-bg-shape {
  position: absolute;
  top: 0;
  right: 50%;
}

/* /clients logo */
/* cta sm */
.cta-sm-bg {
  position: relative;
}

.cta-sm-bg::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  left: calc(100% - 10%);
  top: 0;
  background: url(../images/backgrounds/cta-sm-right.png);
  background-repeat: no-repeat;
  background-size: contain;
}

.cta-sm-bg::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-image: url(../images/backgrounds/cta-sm-left.png);
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 0;
}

/* /cta sm */
/* pricing */
.pricing-table {
  transition: .2s ease;
  position: relative;
}

.pricing-table::before {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: url(../images/backgrounds/pricing-table-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.pricing-table.active {
  background: #86c33a;
  color: #fff;
}

.pricing-table.active h2,
.pricing-table.active h3,
.pricing-table.active h4,
.pricing-table.active h5,
.pricing-table.active h6,
.pricing-table.active .subtitle {
  color: #fff;
}

.pricing-duration {
  transition: .2s linear;
}

.pricing-duration.active {
  font-weight: 600;
  color: #172231;
}

.pricing-switcher {
  text-align: center;
}

.pricing-switcher .fieldset {
  display: inline-block;
  position: relative;
  padding: 2px;
  border-radius: 50em;
  background: #86c33a;
  height: 40px;
  width: 100px;
}

.pricing-switcher input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.pricing-switcher label {
  position: absolute;
  z-index: 1;
  cursor: pointer;
  color: #818385;
  top: 50%;
  transform: translateY(-50%);
  text-transform: uppercase;
  font-size: 16px;
}

.pricing-switcher label.monthly {
  right: 130px;
}

.pricing-switcher label.monthly::before {
  position: absolute;
  content: "";
  height: 30px;
  width: 50px;
  border-radius: 50px;
  top: 50%;
  transform: translateY(-50%);
  right: -85px;
}

.pricing-switcher label.yearly {
  left: 130px;
}

.pricing-switcher label.yearly::before {
  position: absolute;
  content: "";
  height: 30px;
  width: 50px;
  border-radius: 50px;
  top: 50%;
  transform: translateY(-50%);
  left: -85px;
}

.pricing-switcher .switch {
  position: absolute;
  top: 5px;
  left: 5px;
  height: 30px;
  width: 30px;
  background-color: #fff;
  border-radius: 50em;
  transition: transform 0.3s;
}

.pricing-switcher input[type="radio"]:checked + label + .switch,
.pricing-switcher input[type="radio"]:checked + label:nth-of-type(n) + .switch {
  transform: translateX(60px);
}

.pricing-list li {
  position: relative;
  padding: 0 !important;
}

@media only screen and (min-width: 768px) {
  .pricing-list {
    margin: 3em 0 0;
  }
  .pricing-list:after {
    content: "";
    display: table;
    clear: both;
  }
  .pricing-list > li {
    width: 33.3333333333%;
    float: left;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.pricing-wrapper {
  position: relative;
}

.touch .pricing-wrapper {
  perspective: 2000px;
}

.pricing-wrapper.is-switched .is-visible {
  transform: rotateY(180deg);
  animation: rotate 0.5s;
}

.pricing-wrapper.is-switched .is-hidden {
  transform: rotateY(0);
  animation: rotate-inverse 0.5s;
  opacity: 0;
}

.pricing-wrapper.is-switched .is-selected {
  opacity: 1;
}

.pricing-wrapper.is-switched.reverse-animation .is-visible {
  transform: rotateY(-180deg);
  animation: rotate-back 0.5s;
}

.pricing-wrapper.is-switched.reverse-animation .is-hidden {
  transform: rotateY(0);
  animation: rotate-inverse-back 0.5s;
  opacity: 0;
}

.pricing-wrapper.is-switched.reverse-animation .is-selected {
  opacity: 1;
}

.pricing-wrapper > li {
  backface-visibility: hidden;
}

.pricing-wrapper .is-visible {
  position: relative;
  z-index: 5;
}

.pricing-wrapper .is-hidden {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  transform: rotateY(180deg);
}

.pricing-wrapper .is-selected {
  z-index: 3 !important;
}

@keyframes rotate {
  0% {
    transform: perspective(2000px) rotateY(0);
  }
  70% {
    transform: perspective(2000px) rotateY(200deg);
  }
  100% {
    transform: perspective(2000px) rotateY(180deg);
  }
}

@keyframes rotate-inverse {
  0% {
    transform: perspective(2000px) rotateY(-180deg);
  }
  70% {
    transform: perspective(2000px) rotateY(20deg);
  }
  100% {
    transform: perspective(2000px) rotateY(0);
  }
}

@keyframes rotate-back {
  0% {
    transform: perspective(2000px) rotateY(0);
  }
  70% {
    transform: perspective(2000px) rotateY(-200deg);
  }
  100% {
    transform: perspective(2000px) rotateY(-180deg);
  }
}

@keyframes rotate-inverse-back {
  0% {
    transform: perspective(2000px) rotateY(180deg);
  }
  70% {
    transform: perspective(2000px) rotateY(-20deg);
  }
  100% {
    transform: perspective(2000px) rotateY(0);
  }
}

/* /pricing */
/* blog */
.card-type {
  position: absolute;
  top: 30px;
  left: 30px;
  padding: 3px 10px;
  color: #fff;
  background: #86c33a;
  z-index: 1;
}

.card-meta {
  font-size: 14px;
}

.blog-shape-left {
  position: absolute;
  left: 0;
  bottom: 0;
}

.blog-shape-right {
  position: absolute;
  right: 0;
  top: 50%;
}

.clip-half-cycle {
  clip-path: circle(157% at 50% -100%);
}

.title-border-left {
  position: relative;
  padding-left: 30px;
}

.title-border-left::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 5px;
  border-radius: 30px;
  background: #86c33a;
}

.post-search {
  border: 0;
  background: transparent;
  position: absolute;
  bottom: 15px;
  right: 0;
}

.tag-list a {
  display: inline-block;
  padding: 10px 22px;
  background: #fff;
  box-shadow: 0px 5px 20px 0px rgba(51, 77, 128, 0.12);
  border-radius: 35px;
  margin-bottom: 15px;
  color: #818385;
}

.tag-list a:hover {
  background: #86c33a;
  color: #fff;
  box-shadow: 0;
}

blockquote {
  padding: 30px 40px;
  font-style: italic;
  color: #172231;
  border-left: 2px solid #86c33a;
  box-shadow: 0px 15px 39px 0px rgba(8, 18, 109, 0.1);
  position: relative;
  margin-bottom: 40px;
}

blockquote::before {
  position: absolute;
  content: "\f10d";
  font-family: "FontAwesome";
  font-size: 100px;
  color: rgba(134, 195, 58, 0.3);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-style: normal;
}

blockquote p {
  font-style: normal;
  margin-top: 10px;
}

/* /blog */
/* footer */
.list-styled {
  padding-left: 0;
}

.list-styled li {
  position: relative;
  padding-left: 20px;
}

.list-styled li::before {
  position: absolute;
  content: "\f105";
  font-family: "FontAwesome";
  font-size: 14px;
  left: 0;
  top: 1px;
  color: #86c33a;
  transition: .3s ease;
}

.list-styled li:hover::before {
  color: #86c33a !important;
}

.list-styled li:hover a {
  text-decoration: underline;
}

.list-styled li.text-light::before {
  color: #ddd;
}

.list-styled.style-circle li::before {
  content: "\f10c";
}

.newsletter-form {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 35px;
}

.newsletter-form:focus {
  background: rgba(255, 255, 255, 0.1);
}

.newsletter-form::placeholder {
  color: #fff;
}

.btn-subscribe {
  position: absolute;
  top: 0;
  right: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* /footer */
