// Color Variables
$primary-color: #86c33a;
$secondary-color: #252d39;
$text-color: #818385;
$text-color-dark: #172231;
$body-color: #fff;
$border-color: #e7e7e7;
$black: #000;
$white: #fff;
$gray: #f5f6fa;

// Font Variables
$primary-font: 'Open Sans', sans-serif;
$secondary-font: 'Barlow Condensed', sans-serif;
$icon-font: 'FontAwesome';