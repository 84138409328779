/*  typography */
@import url('https://fonts.googleapis.com/css?family=Barlow+Condensed:300,400,500,600,700|Open+Sans:300,400,600,700');

body {
  line-height: 1.6;
  font-family: $primary-font;
  -webkit-font-smoothing: antialiased;
  font-size: 15px;
  color: $text-color;
}

p, .paragraph {
  font-weight: 400;
  color: $text-color;
  font-size: 15px;
  line-height: 1.6;
  font-family: $primary-font;
}

h1,h2,h3,h4,h5,h6 {
  color: $text-color-dark;
  font-family: $secondary-font;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1.2;
  transition: .2s ease;
}

h1, .h1{
  font-size: 70px;
}

h2, .h2{
  font-size: 48px;
}

h3, .h3{
  font-size: 44px;
}

h4, .h4{
  font-size: 22px;
}

h5, .h5{
  font-size: 18px;
}

h6, .h6{
  font-size: 16px;
}
/* /typography */