/* Button style */
.btn {
  font-size: 15px;
  font-family: $primary-font;
  text-transform: uppercase;
  padding: 15.5px 28px;
  border-radius: 35px;
  font-weight: 700;
  border: 1px solid;
  position: relative;
  z-index: 1;
  transition: .3s ease-in;

  &:focus {
    outline: 0;
    box-shadow: none !important;
  }

  &:active {
    box-shadow: none;
  }
}

.btn-lg{
  font-size: 20px;
  padding: 19px 35px;
}

.btn-md{
  font-size: 18px;
  padding: 17px 30px;
}

.btn-sm{
  font-size: 12px;
  padding: 5px 20px;
}

.btn-primary {
  background: $primary-color;
  color: $white;
  border-color: $primary-color;

  &:active {
    background: darken($color: $primary-color, $amount: 10) !important;
    border-color: darken($color: $primary-color, $amount: 10);
  }

  &:hover {
    background: darken($color: $primary-color, $amount: 10);
    border-color: darken($color: $primary-color, $amount: 10);
  }
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  color: $white;
  background-color: darken($color: $primary-color, $amount: 10);
  border-color: darken($color: $primary-color, $amount: 10);
}



.btn-gray {
  background: $gray;
  color: $text-color-dark;
  border-color: $gray;

  &:active {
    background: $primary-color !important;
    border-color: $primary-color;
    color: $white;
  }

  &:hover {
    background: $primary-color;
    border-color: $primary-color;
    color: $white;
  }
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  color: $white;
  background-color: darken($color: $primary-color, $amount: 10);
  border-color: darken($color: $primary-color, $amount: 10);
}



.btn-secondary {
  background: transparent;
  color: $text-color-dark;
  padding-left: 0;
  border: 0;

  &:active {
    background: transparent !important;
    color: $primary-color;
  }

  &:hover {
    background: transparent;
    color: $primary-color;
  }
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
  color: $primary-color;
  background-color: transparent;
  border: 0;
}


.btn-primary-outline {
  background: transparent;
  color: $text-color-dark;
  border: 2px solid $text-color-dark;

  &:active {
    background: $primary-color !important;
    border-color: $primary-color;
    color: $white;
  }

  &:hover {
    background: $primary-color;
    border-color: $primary-color;
    color: $white;
  }
}

.btn-light {
  background: $white;
  color: $text-color-dark;
  border-color: $border-color;

  &:active {
    background: transparent !important;
    border-color: $white;
    color: $white;
  }

  &:hover {
    background: transparent;
    border-color: $white;
    color: $white;
  }
}


.btn-light-alt {

  &:active {
    background: $primary-color !important;
    border-color: $primary-color;
    color: $white;
  }

  &:hover {
    background: $primary-color;
    border-color: $primary-color;
    color: $white;
  }
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show>.btn-light.dropdown-toggle {
  background: transparent;
  border-color: $white;
  color: $white;
}


.btn-light-outline {
  background: transparent;
  color: $white;
  border: 2px solid $white;

  &:active {
    background: darken($color: $primary-color, $amount: 10) !important;
    border-color: darken($color: $primary-color, $amount: 10);
    color: $white;
  }

  &:hover {
    background: darken($color: $primary-color, $amount: 10);
    border-color: darken($color: $primary-color, $amount: 10);
    color: $white;
  }
}

.btn-arrow {
  transition: .2s ease;
  &::before {
    position: absolute;
    content: '';
    height: 8px;
    width: 8px;
    background: transparent;
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
    border-right: 2px solid $black;
    border-bottom: 2px solid $black;
    left: calc( 85% + 5px);
    transition: .2s ease;
  }

  &::after {
    position: absolute;
    content: '';
    height: 2px;
    width: 15px;
    top: 50%;
    transform: translateY(-50%);
    background: $black;
    transition: .2s ease;
    left: 85%;
  }

  &:hover {
    &::before {
      left: calc( 85% + 10px);
      border-color: $primary-color;
    }
    &::after{
      width: 20px;
      background: $primary-color;
    }
  }
}

/* hover effect */
.hover-ripple {
  transition: .2s ease-in;

  &:hover {
    transform: scale(.97);

    &::after {
      animation: sonarEffect 1s ease-out 50ms;
    }
  }

  &::after {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    top: 0;
    left: 0;
    padding: 0;
    z-index: -1;
    opacity: 0;
    transform: scale(.9);
    border-radius: inherit;
  }

  &.ripple-white {
    &:hover {
      &::after {
        animation: sonarEffectWhite 1s ease-out 50ms;
      }
    }
  }
}

@keyframes sonarEffect {
  0% {
    opacity: 0.1;
  }

  40% {
    opacity: 0.3;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px $primary-color, 0 0 0 10px rgba(255, 255, 255, 0.2);
  }

  100% {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px $primary-color, 0 0 0 10px rgba(255, 255, 255, 0.2);
    transform: scale(1.2);
    opacity: 0;
  }
}

@keyframes sonarEffectWhite {
  0% {
    opacity: 0.1;
  }

  40% {
    opacity: 0.3;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px $white, 0 0 0 10px rgba(255, 255, 255, 0.2);
  }

  100% {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px $white, 0 0 0 10px rgba(255, 255, 255, 0.2);
    transform: scale(1.2);
    opacity: 0;
  }
}
/* /button style */