/* banner feature */
.banner-feature-wrapper {
  transform: translateY(-100px);
}

.hover-bg-dark {

  &:hover,
  &.active {
    background: $secondary-color !important;

    * {
      color: $white !important;
    }
  }
}
/* /banner feature */

/* about */
.image-bg {
  position: absolute;
  top: 20%;
  right: 0;
  z-index: -1;
}

.about-bg-shape {
  position: absolute;
  top: 0;
  right: 0;
}

.philosophy-bg-shape {
  position: absolute;
  left: 0;
  bottom: 0;
}
/* /about */

/* funfacts */
.funfacts {
  overflow-x: hidden;

  @include desktop {
    background: $secondary-color;
  }

  .container {
    transform: translateX(-50px);
    position: relative;

    &::before {
      position: absolute;
      content: "";
      right: 100%;
      top: 0;
      height: 100%;
      width: 100%;
      background: $secondary-color;
    }

    @include desktop {
      transform: translate(0);

      &::before {
        display: none;
      }
    }
  }

  h3 {
    @include desktop {
      font-size: 30px;
    }

    @include tablet {
      font-size: 35px;
    }
  }

  .icon-lg {
    @include desktop {
      font-size: 40px;
    }

    @include tablet {
      font-size: 50px;
    }
  }
}
/* /funfacts */

/* feature */
.feature-img-bg {
  position: relative;

  &::before {
    position: absolute;
    content: "";
    background: url(../images/backgrounds/feature-bg.png);
    top: -100px;
    right: -120px;
    height: 150%;
    width: 120%;
    z-index: -1;
    background-repeat: no-repeat;
    background-size: contain;

    @include desktop {
      display: none;
    }
  }
}

.play-video {
  position: absolute;
  left: 0;
  bottom: 20%;
}

.play-icon {
  display: inline-block;
  min-width: 90px;
  height: 90px;
  text-align: center;
  line-height: 90px;
  border-radius: 50%;
  box-shadow: 0px 15px 39px 0px rgba(8, 18, 109, 0.1);
  font-size: 30px;
}
/* /feature */

/* service */
.service-wrapper {
  transform: translateY(-200px);
}

.card-icon {
  position: absolute;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background: $primary-color;
  text-align: center;
  line-height: 60px;
  color: $white;
  font-size: 32px;
  left: 15px;
  top: -90px;
}

.service-bg-dots {
  position: relative;

  &::before {
    position: absolute;
    content: "";
    background: url(../images/backgrounds/service-page-2.png);
    top: 0px;
    right: 0px;
    height: 100%;
    width: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
  }
}
/* /service */

/* team */
.team-member {
  &-img {
    img {
      position: relative;
      z-index: 2;
    }

    &::before {
      position: absolute;
      content: "";
      background: url(../images/backgrounds/team-member-bg.png);
      top: 0px;
      right: 0px;
      height: 60%;
      width: 60%;
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 1;
      transition: .2s ease;
    }

    &::after {
      position: absolute;
      content: "";
      background: url(../images/backgrounds/team-member-bg.png);
      bottom: -40px;
      left: 0px;
      height: 60%;
      width: 60%;
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 1;
      transition: .2s ease;
    }
  }

  &:hover {
    .team-member-img {
      &::before {
        top: -20px;
        right: -20px;
      }

      &::after {
        bottom: -60px;
        left: -20px;
      }
    }

    .hover-icon {
      transform: scale(1);
      opacity: 1;

      .social-icon {
        a {
          transform: scale(1);
        }
      }
    }
  }
}

.hover-icon {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($color: $primary-color, $alpha: .9);
  transition: .2s ease;
  transform: scale(.8);
  z-index: 3;
  opacity: 0;

  .social-icon {
    a {
      transform: scale(0);
    }
  }
}
/* /team */

/* project */
.project-item {
  img{
    transition: .4s ease-in;
  }
  &:hover {
    img{
      transform: scale(1.05);
    }
    .hover-overlay {
      opacity: 1;

      a {
        transform: translateY(-50%) scale(1);
      }
    }
  }
}

.hover-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  text-align: center;
  background: rgba($color: $primary-color, $alpha: .9);
  transition: .4s ease;
  opacity: 0;

  a {
    position: relative;
    font-size: 46px;
    color: $white;
    top: 50%;
    transform: translateY(-50%) scale(0);
    transition: .4s ease;
    display: inline-block;
  }
}

.uni-plus {
  position: relative;
  padding: 0 20px;

  &::before {
    position: absolute;
    content: '';
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 40px;
    width: 2px;
    background: $white;
  }

  &::after {
    position: absolute;
    content: '';
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 2px;
    width: 40px;
    background: $white;
  }
}

.filter-controls {
  li {
    cursor: pointer;

    &.active {
      color: $primary-color !important;
    }
  }
}
/* /project */

/* testimonial */
.bg-dots {
  position: relative;

  &::before {
    position: absolute;
    content: "";
    background: url(../images/backgrounds/testimonial-bg-1.png);
    top: 0px;
    right: 0px;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
  }

  .container {
    position: relative;
    z-index: 2;
  }
}

.testimonial-slider {

  .nextArrow,
  .prevArrow {
    opacity: 0;
    transition-delay: 1s;
  }

  &:hover {
    .nextArrow {
      opacity: 1;
      right: -100px;
      transition-delay: 0s;
    }

    .prevArrow {
      opacity: 1;
      left: -100px;
      transition-delay: 0s;
    }
  }
}

.bg-quote {
  position: relative;

  &::before {
    position: absolute;
    content: "\f10d";
    font-family: $icon-font;
    font-size: 150px;
    color: rgba($color: $primary-color, $alpha: .3);
    top: -10px;
    line-height: 1;
    left: 50%;
    transform: translateX(-50%);
  }
}
/* /testimonial */

/* clients logo */
.clients-logo {
  text-align: center;
  position: relative;
  height: 120px;
  width: 180px;

  &:hover {
    .white {
      opacity: 0;
    }

    .primary {
      opacity: 1;
    }
  }

  .white {
    opacity: 1;
    position: absolute;
    left: 0;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    text-align: center;
    padding: 0 10px;
  }

  .primary {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    text-align: center;
    padding: 0 10px;
  }
}

.customer-bg-shape {
  position: absolute;
  top: 0;
  right: 50%;
}
/* /clients logo */